// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyASbqMEMDR_SEXTEzPoWaUwcLUm0Q9QYoU",
  authDomain: "evangel-stream.firebaseapp.com",
  projectId: "evangel-stream",
  storageBucket: "evangel-stream.appspot.com",
  messagingSenderId: "837911234958",
  appId: "1:837911234958:web:ff1674d0d2391796fe3cdf",
  measurementId: "G-RZ118XP06Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
